import { SVGProps } from "react";

export default function Microphone(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 48 48"
      {...props}
    >
      <defs>
        <clipPath id="property-1-microphone__a">
          <path d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#property-1-microphone__a)">
        <path d="M38 0c-5.445 0-9.887 4.387-9.99 9.809l-.895 2.682L1.62 41.039 3.585 43 .293 46.293l1.414 1.414L5 44.413l1.95 1.946 13.441-11.588A.982.982 0 0 0 21 35c.21 0 .4-.061.561-.17l.149-.121L23 33.674V48h2V32.068l2-1.604V48h2V28.859l1.52-1.221.091-.189c.292-.58.395-1.204.386-1.822l5.495-4.737 3.079-1.026C44.336 19.105 48 14.975 48 10c0-5.51-4.49-10-10-10Zm-9.1 26.379-6.7 5.381c-.181-.99-.58-1.9-.9-2.52l7.12-5.711c.39.861.76 2.02.48 2.85Zm1.632-2.987a10.009 10.009 0 0 0-.982-1.942l-.6-.91-10.29 8.25.51.76c.299.46.917 1.635 1.069 2.711L7.05 43.64l-2.67-2.681L28.432 14.02c3.053.192 6.266 2.395 6.54 5.547l-4.44 3.825Zm6.341-4.738c-.684-3.571-4.035-6.035-7.52-6.553l.356-1.07c3.827.332 7.821 3.288 8.252 7.26l-1.088.363Zm3.024-.887c-.669-4.478-4.881-8.237-9.836-8.715a7.955 7.955 0 0 1 1.637-3.963c.278-.067.457-.102.466-.103 2.519-.42 5.479.566 7.922 2.637 2.437 2.063 3.897 4.802 3.914 7.332-.006.092-.002.196.006.32a8.016 8.016 0 0 1-4.109 2.492Zm1.482-11.671c-2.157-1.826-4.693-2.932-7.128-3.158A7.93 7.93 0 0 1 38 2c4.41 0 8 3.59 8 8 0 .846-.139 1.66-.384 2.426-.653-2.296-2.132-4.545-4.237-6.33Z" />
      </g>
    </svg>
  );
}
