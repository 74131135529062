import classNames from "classnames";

interface Props {
  className?: string;
}

export default function DottedBackground({ className }: Props) {
  return (
    <div
      className={classNames(
        "absolute -bottom-10 -left-2 -right-2 -top-10 overflow-hidden",
        className
      )}
    >
      <svg className="h-full w-full">
        <defs>
          <linearGradient
            id="gradient-opacity"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor="white" stopOpacity="0" />
            <stop offset="70%" stopColor="white" stopOpacity="1" />
            <stop offset="100%" stopColor="white" stopOpacity="0" />
          </linearGradient>

          <mask id="opacity-mask">
            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              fill="url(#gradient-opacity)"
            />
          </mask>
        </defs>

        <pattern
          id="dots"
          x="21"
          y="-15"
          width="24"
          height="24"
          patternUnits="userSpaceOnUse"
          patternTransform="translate(-1,-1)"
        >
          <circle cx="1" cy="1" r="1" className="fill-white" />
        </pattern>

        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          fill="url(#dots)"
          mask="url(#opacity-mask)"
        />
      </svg>
    </div>
  );
}
