import Instrument from "shared/games/instruments/Instrument";
import { Game } from "shared/games/types/Game";
import { Todo } from "shared/todos/Todo";
import GuitarAcoustic from "ui/icons/instruments/acoustic-guitar";
import Microphone from "ui/icons/instruments/microphone";
import Synth from "ui/icons/instruments/synthesizer";
import Drum from "ui/icons/instruments/snare-drum";
import { Technique } from "shared/games/technique";
import Compressor from "ui/icons/techniques/compressor";
import EQ from "ui/icons/techniques/eq";
import Pan from "ui/icons/techniques/pan";
import Volume from "ui/icons/techniques/volume";
import allTechniqueGameDescriptions from "shared/games/allTechniqueGameDescriptions";

type All = "all";

export interface EarTrainingPageProps<P extends PageConfig> {
  instruments: Instrument[] | All;
  games: Game[] | All;
  title: string;
  subtitle: string;
  todosFilterCb: (todo: Todo) => boolean;
  icon: (props: { className: string }) => React.ReactNode;
  urlParam: P;
}

export type PageConfig = InstrumentPageParams | TechniquePageParams;

export type AllowedPageTypes =
  | InstrumentPageParams["type"]
  | TechniquePageParams["type"];

export type InstrumentPageParams = {
  type: "instrument";
  instrument: AllowedInstrumentPages;
};

export type TechniquePageParams = {
  type: "technique";
  technique: AllowedTechniquePages;
};

export type AllowedInstrumentPages = typeof instrumentsPageKeys[number];

export function isAllowedInstrumentPage(
  page: string
): page is AllowedInstrumentPages {
  return instrumentsPageKeys.includes(page as AllowedInstrumentPages);
}

export type AllowedTechniquePages = Exclude<Technique, Technique.mixPuzzle>;

export function isAllowedTechniquePage(
  page: string
): page is AllowedTechniquePages {
  return Object.values(Technique).includes(page as Technique);
}

const drums = new Set([
  Instrument.drmSet,
  Instrument.drmKick,
  Instrument.drmSnare,
  Instrument.drmOh,
  Instrument.percTambo,
  Instrument.percBongos,
  Instrument.percShaker,
]);

const vox = new Set([Instrument.voxFemale]);

const guitars = new Set([
  Instrument.gtrAccFingerPicked,
  Instrument.gtrAccStrummed,
  Instrument.gtrAccLead,
  Instrument.gtrBass,
]);

const pads = new Set([
  Instrument.synthPad,
  Instrument.synthLead,
  Instrument.stringEnsemble,
]);

const compressorGames = new Set(
  Object.values(allTechniqueGameDescriptions.compression.games).map(
    ({ game }) => game
  )
);

const eqGames = new Set(
  Object.values(allTechniqueGameDescriptions.eq.games).map(({ game }) => game)
);

const panGames = new Set(
  Object.values(allTechniqueGameDescriptions.pan.games).map(({ game }) => game)
);

const volumeGames = new Set(
  Object.values(allTechniqueGameDescriptions.volume.games).map(
    ({ game }) => game
  )
);

export const instrumentsPageKeys = [
  "drums",
  "vocals",
  "guitars",
  "pads",
] as const;

export const instrumentPages: {
  [instrument in AllowedInstrumentPages]: EarTrainingPageProps<InstrumentPageParams>;
} = {
  drums: {
    urlParam: { type: "instrument", instrument: "drums" },
    title: "Learn to Mix Drums",
    subtitle: "",

    games: "all",
    instruments: Array.from(drums),
    todosFilterCb: (todo) => drums.has(todo.looop_instrument),
    icon: ({ className }) => <Drum className={className} />,
  },

  vocals: {
    urlParam: { type: "instrument", instrument: "vocals" },
    title: "Learn to Mix Vocals",
    subtitle: "",

    games: "all",
    instruments: Array.from(vox),
    todosFilterCb: (todo) => vox.has(todo.looop_instrument),
    icon: ({ className }) => <Microphone className={className} />,
  },

  guitars: {
    urlParam: { type: "instrument", instrument: "guitars" },
    title: "Learn to Mix Guitars",
    subtitle: "",

    games: "all",
    instruments: Array.from(guitars),
    todosFilterCb: (todo) => guitars.has(todo.looop_instrument),
    icon: ({ className }) => <GuitarAcoustic className={className} />,
  },

  pads: {
    urlParam: { type: "instrument", instrument: "pads" },
    title: "Learn to Mix Pads",
    subtitle: `
    `,

    games: "all",
    instruments: Array.from(pads),
    todosFilterCb: (todo) => pads.has(todo.looop_instrument),
    icon: ({ className }) => <Synth className={className} />,
  },
};

export const techniquePages: {
  [technique in AllowedTechniquePages]: EarTrainingPageProps<TechniquePageParams>;
} = {
  compression: {
    urlParam: { type: "technique", technique: Technique.compression },
    title: "Learn to Hear Compression",
    subtitle: "",

    games: Array.from(compressorGames),
    instruments: "all",
    todosFilterCb: (todo) => compressorGames.has(todo.game),
    icon: ({ className }) => <Compressor className={className} />,
  },

  eq: {
    urlParam: { type: "technique", technique: Technique.eq },
    title: "Learn to Hear Equalization",
    subtitle: "",

    games: Array.from(eqGames),
    instruments: "all",
    todosFilterCb: (todo) => eqGames.has(todo.game),
    icon: ({ className }) => <EQ className={className} />,
  },

  pan: {
    urlParam: { type: "technique", technique: Technique.pan },
    title: "Learn to Pan Across the Stereo Field",
    subtitle: "",

    games: Array.from(panGames),
    instruments: "all",
    todosFilterCb: (todo) => panGames.has(todo.game),
    icon: ({ className }) => <Pan className={className} />,
  },

  volume: {
    urlParam: { type: "technique", technique: Technique.volume },
    title: "Learn to Hear Volume Differences",
    subtitle: "",

    games: Array.from(volumeGames),
    instruments: "all",
    todosFilterCb: (todo) => volumeGames.has(todo.game),
    icon: ({ className }) => <Volume className={className} />,
  },
};

export default async function getPageConfig(
  params: PageConfig
): Promise<EarTrainingPageProps<PageConfig> | null> {
  console.log({ params });
  if (params.type === "instrument") {
    return instrumentPages[params.instrument];
  }

  if (params.type === "technique") {
    return techniquePages[params.technique];
  }

  throw new Error("invalid params");
}
