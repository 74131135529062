import { SVGProps } from "react";

export default function Ear(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 48 48"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M25.696 0C16.492 0 9 7.497 9 16.706c0 .557.456 1.013 1.012 1.013.557 0 1.013-.456 1.013-1.013 0-8.095 6.581-14.68 14.67-14.68 8.09 0 14.672 6.586 14.672 14.68 0 4.179-5.01 13.098-6.667 16.046l-.006.011c-.512.914-.815 2.195-1.165 3.678l-.01.04-.013.057c-.492 2.114-1.05 4.509-2.325 6.356-1.418 2.067-3.524 3.08-6.43 3.08h-.01c-3.99 0-7.118-3.009-7.118-6.838 0-.558-.455-1.014-1.012-1.014-.557 0-1.013.456-1.013 1.014 0 2.41.962 4.66 2.704 6.321C19.012 47.088 21.3 48 23.74 48h.01c3.574 0 6.298-1.337 8.1-3.961 1.499-2.178 2.136-4.914 2.643-7.092l.014-.062c.299-1.274.579-2.471.948-3.129 6.216-11.053 6.935-15.024 6.935-17.05C42.391 7.496 34.9 0 25.695 0h.001Zm3.037 24.395c3.301-1.418 5.184-4.893 4.577-8.459-.516-3.1-2.916-5.602-5.964-6.24-2.328-.487-4.718.08-6.53 1.56a7.726 7.726 0 0 0-2.856 6.007c0 .558.456 1.013 1.013 1.013.557 0 1.012-.455 1.012-1.013 0-1.732.77-3.343 2.107-4.437 1.336-1.094 3.108-1.51 4.84-1.145 2.207.456 4.009 2.34 4.383 4.58.446 2.654-.941 5.227-3.392 6.27-.172.071-1.863.993-3.523 2.473-2.248 2.015-3.361 4.052-3.26 6.058v2.461c0 .558.455 1.014 1.012 1.014.557 0 1.013-.456 1.013-1.014v-2.482c0-.012.003-.023.006-.033a.073.073 0 0 0 .004-.017c-.162-3.04 4.576-6.099 5.559-6.595ZM22.7 38.728a1.088 1.088 0 1 0 0-2.175 1.088 1.088 0 0 0 0 2.175Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
