"use client";

import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import Image from "next/image";
import { marketingLandingPages } from "routes";
import Ear from "ui/icons/instruments/ear";
import Microphone from "ui/icons/instruments/microphone";
import Navbar from "ui/organisms/Navbar/Navbar";
import DottedBackground from "ui/templates/Marketing/templates/DottedBackground";
import { GeneralPageLayout } from "ui/templates/PageLayouts";
import Link from "util/Link";
import useAuth from "util/useAuth/useAuth";
import { techniquePages } from "./ear-training/mixing/__helpers/getPageConfig";

export default function HomePage() {
  const auth = useAuth();

  return (
    <GeneralPageLayout className={"pb-10"} auth={auth}>
      {({ className }) => (
        <div className={"bg-gradient-to-b from-purple-600/10 to-purple-600/0"}>
          <div className={"md:pb-10 pb-6"} />

          <section
            className={classNames(
              "text-center text-white md:mb-14 mb-8",
              className
            )}
          >
            <h2 className={"font-brik md:text-6xl text-3xl leading-none"}>
              Welcome To
              <br />
              Bedroom Producer
            </h2>

            <p className={"md:text-xl max-w-xs leading-none mx-auto"}>
              Improve your music making skills with our fun games and tools.
            </p>
          </section>

          <div className="relative overflow-hidden">
            <DottedBackground className={"hidden md:block"} />

            <div className={className}>
              <FeatureCards />
            </div>
          </div>
        </div>
      )}
    </GeneralPageLayout>
  );
}

function FeatureCards() {
  return (
    <section className={"grid gap-4 md:grid-cols-2 grid-cols-1"}>
      <FeatureCard
        title={"Vocal Driller For Singers"}
        renderIcon={({ className }) => <Microphone className={className} />}
        className="bg-gradient-to-r from-blue-500 to-blue-400"
        textClassName={"text-blue-500"}
        href={marketingLandingPages.vocalDriller}
        description={"Your new favorite singing practice tool."}
        renderShowImage={() => (
          <Image
            className={"rounded-tl w-full object-cover object-top"}
            src={"/images/home-page/vocal-driller-mobile.png"}
            alt=""
            width={500}
            height={400}
          />
        )}
      />

      <FeatureCard
        title={"Ear Training For Mixers"}
        renderIcon={({ className }) => <Ear className={className} />}
        className="bg-gradient-to-r from-purple-500 to-purple-400"
        textClassName={"text-purple-500"}
        href={marketingLandingPages.mixingGames}
        description={"Ear training games to practice mixing."}
        renderShowImage={EarTrainingFeatureList}
      />
    </section>
  );
}

function EarTrainingFeatureList() {
  return (
    <ul className={"md:grid grid-rows-4 gap-4 h-full text-white px-2"}>
      {Object.values(techniquePages).map(({ title, icon, urlParam }) => (
        <li key={title} className="flex items-center">
          <div className={"flex items-center justify-center mr-4 h-full"}>
            {icon({ className: "md:h-full h-14" })}
          </div>

          <div className={"md:text-xl leading-tight"}>{title}</div>
        </li>
      ))}
    </ul>
  );
}

interface FeatureCardProps {
  renderIcon: ({ className }: { className: string }) => JSX.Element;
  className: string;
  title: string;
  renderShowImage: () => JSX.Element;
  textClassName: string;
  href: string;
  description: string;
}

function FeatureCard({
  renderIcon,
  title,
  className,
  renderShowImage,
  textClassName,
  href,
  description,
}: FeatureCardProps) {
  return (
    <Link href={href} className="group">
      <div
        className={classNames(
          "rounded-2xl relative group-hover:shadow-xl transition-all md:group-hover:opacity-100 md:opacity-80",
          className,
          textClassName
        )}
      >
        <div className={"text-white lg:px-10 lg:pt-11 px-5 pt-5 lg:mb-4 mb-3"}>
          <h2 className={"text-xl font-brik flex justify-between"}>
            <div>
              {renderIcon({ className: "h-4 w-4 inline-block mr-2" })}
              {title}
            </div>

            <div
              className={
                "rounded bg-white text-inherit w-6 h-6 flex items-center justify-center opacity-0 group-hover:opacity-100 group-hover:translate-x-0 translate-x-4 transition-all"
              }
            >
              <ArrowRightOnRectangleIcon
                className={classNames("w-4 stroke-2", textClassName)}
              />
            </div>
          </h2>

          <p className={"leading-tight"}>{description}</p>
        </div>

        <div className={"flex justify-end overflow-hidden"}>
          <div
            className={
              "md:h-96 lg:w-[calc(100%-40px)] w-[calc(100%-20px)] bg-black rounded-tl-2xl overflow-hidden p-4 md:group-hover:shadow-2xl md:transition-all md:group-hover:translate-y-2"
            }
          >
            {renderShowImage()}
          </div>
        </div>
      </div>
    </Link>
  );
}
